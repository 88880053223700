// Vue
import { createApp } from 'vue';
import App from '../components/App.vue';
// Vue Router
import { createRouter, createWebHashHistory } from 'vue-router';
import routes from '../routes';
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

const lightTheme = {
  name: 'lightTheme',
  dark: false,
  colors: {
    'link-color': '#000',
    'header-bg-color': '#fff',
    'body-bg-color': '#fff',
  },
}
const darkTheme = {
  name: 'darkTheme',
  dark: true,
  colors: {
    'link-color': '#fff',
    'header-bg-color': '#212529',
    'body-bg-color': '#121212',
  },
}
const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'darkTheme',
    themes: {
      lightTheme,
      darkTheme,
    },
  },
})

const app = createApp(App);
app.use(router);
app.use(vuetify);
app.mount('#app');

