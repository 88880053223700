<template>
  <v-container class='text-center py-2'>
    <template v-for="(link, index) in navLinks" :key=index>
      <a class='router-link' :href=link.path :target=link.target>{{ link.name }}</a>
    </template>
  </v-container>
</template>

<script>
  import { useTheme } from 'vuetify';

  export default {
    setup() {
      const theme = useTheme();

      return {
        navLinks: [
          { name: 'Resume', path: '/tkfarr_resume.pdf', target: '_blank' },
          { name: 'Resources', path: '/resources', target: '' }
        ],
        theme,
      }
    },
  }
</script>

<style scoped>
  .router-link {
    margin: 10px;
    text-decoration: none;
    color: v-bind("theme.global.current.value.colors['link-color']");
  }
  .router-link:hover {
    box-shadow: 0 1px 0 0 v-bind("theme.global.current.value.colors['link-color']");
  }
  .router-link-active {
    box-shadow: 0 2px 0 0 v-bind("theme.global.current.value.colors['link-color']");
  }
</style>
