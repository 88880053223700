<template>
  <v-container class='text-center py-2'>
    <template v-for="(btn, index) in btns" :key=index>
      <v-btn :href=btn.href target="_blank" class="mx-2" variant='text' icon>
        <v-icon>{{ btn.icon }}</v-icon>
      </v-btn>
    </template>
  </v-container>
</template>

<script>
  export default {
    data: function () {
      return {
        btns: [
          { icon: 'mdi-github', href: 'https://github.com/tkfarr' },
          { icon: 'mdi-linkedin', href: 'https://linkedin.com/in/tkfarr' },
          { icon: 'mdi-email', href: 'mailto:thomaskekoafarr@gmail.com' }
        ]
      }
    }
  }
</script>
