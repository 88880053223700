<template>
  <v-container :class=containerClass>
    <router-link class='link text-link-color' to='/'>
      <h1>{{ text }}</h1>
    </router-link>
  </v-container>
</template>

<script>
  export default {
    props: {
      containerClass: String,
    },
    data: function () {
      return {
        text: 'Thomas Farr'
      }
    }
  }
</script>

<style scoped>
  .link {
    text-decoration: none;
    text-align: center;
  }
</style>
