<template>
  <v-app id='home-layout'>
    <v-container>
      <app-title containerClass='py-2' />
      <nav-links />
      <social-links />
    </v-container>
  </v-app>
</template>

<script>
  import { useTheme } from 'vuetify';
  import Title from '../Title.vue';
  import NavLinks from '../NavLinks.vue';
  import SocialLinks from '../SocialLinks.vue';

  export default {
    components: {
      'app-title': Title,
      'nav-links': NavLinks,
      'social-links': SocialLinks,
    },
    setup() {
      const theme = useTheme();

      return {
        theme,
      }
    },
  };
</script>

<style>
  #home-layout > div {
    justify-content: center;
  }
</style>

<style scoped>
  #home-layout {
    background: v-bind("theme.global.current.value.colors['header-bg-color']");
  }
  .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to {
    opacity: 0;
  }
</style>
