import HomeLayout from './components/layouts/HomeLayout.vue';

export default [
  {
    path: '/',
    component: HomeLayout
  },
  {
    path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
    redirect: '/',
  }
];
